import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  memo,
  forwardRef,
  useImperativeHandle,
  useCallback
} from 'react'
import { formatDateString } from '../../utils'
import { headerData } from './header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import InspectionMediaModal from '../../components/inspection-media-modal'
import AddInspectionModal from '../add-inspection-modal'
import InspectionAssignmentModal from '../inspection-assignment-modal'
import ConfirmationModal from '../../components/confirmation-modal'
import moment from 'moment'
import AllInspectionNoteModal from '../all-inspection-note-modal'
import { getOutsideFilters } from './header-data'
import { useModals } from 'hooks'
import BigLoading from '../../components/big-loading'
import Paginator from '../../components/react-table-pagination'
import styled from 'styled-components'
import {
  useTable,
  useBlockLayout,
  useFilters,
  useRowSelect,
  useSortBy,
  usePagination
} from 'react-table'
import { FixedSizeList } from 'react-window'
import { exportSingleInspectionCSV } from './components/reusableInspectionMethods'
import { edit, exportFile, reopen, calendar, closeFilled } from 'ui/icons'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { icons } from './icons'
import { usePrevious } from '../../helpers/hooks'
import _ from 'lodash'
// For Date filter
import DayPickerInput from 'react-day-picker/DayPickerInput'
import dateFnsFormat from 'date-fns/format'
import cn from 'classnames'
import {
  calendarConfig,
  parseDate,
  formatDate
} from '../../utils/calendarInputHelper'
import { numberOfDays } from '../../utils'
import { DateUtils } from 'react-day-picker/DayPicker'
import { usePermissionGate } from '../../helpers/hooks'

import './index.scss'
import { cloneDeep, groupBy, isEmpty } from 'lodash'
import '../../styles/common.scss'

// Table styles
const Styles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  input {
    margin-top: 8px !important;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e5e9f2;
    margin: 0px auto;
    outline: none;
    font-size: 10px;
  }
  select {
    width: 100%;
    margin-top: 8px !important;
  }
  .inspection-table {
    display: inline-block;
    border-spacing: 0;
    position: relative;
    .tr {
      position: relative;
      :first-child {
        .th {
          display: none !important;
          border-top: 1px solid #e5e9f2;
        }
      }
    }
    .th {
      text-align: center;
      padding: 0px;
      :first-child {
        //border: 1px solid #e5e9f2;
      }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
    }
  }
`
// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, setFilter },
  toggleAllRowsSelected,
  selectedFlatRows
}) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}
      placeholder={'Search'}
    />
  )
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  toggleAllRowsSelected,
  selectedFlatRows
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  let options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // To remove empty values from filter
  options = (options || []).filter(function(e) {
    return e
  })
  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}>
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const IndeterminateCheckbox = React.forwardRef(
  (
    {
      indeterminate,
      currentRow,
      rows,
      toggleRowSelected,
      enableManualSelection,
      showTooltip,
      onChange,
      ...rest
    },
    ref
  ) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    const handleChange = () => {
      const filteredRows = (rows || []).filter(
        ({ original }) =>
          original?.unit_id === currentRow?.original?.unit_id &&
          original?.canSchedule
      )

      return (filteredRows || []).forEach(({ id, isSelected }) => {
        toggleRowSelected(id, !isSelected)
      })
    }

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          onChange={e => {
            enableManualSelection ? handleChange() : onChange(e)
          }}
          {...rest}
          title={
            showTooltip
              ? 'The capacity for the selected date has been reached.'
              : 'Toggle Row Selected'
          }
        />
      </>
    )
  }
)

const Table = memo(
  forwardRef(
    (
      {
        columns,
        data,
        onRowSelectStateChange,
        isPhotoLoading,
        setSelectedPage,
        setSelectedPageRowData,
        rowsPerPage,
        setRowsPerPage,
        setIsFilterApplied
      },
      ref
    ) => {
      // Use the state and functions returned from useTable to build your UI

      const defaultColumn = React.useMemo(
        () => ({
          width: 100,
          Filter: DefaultColumnFilter
        }),
        []
      )
      const filterTypes = React.useMemo(
        () => ({
          customEquals: (rows, id, filterValue) => {
            if (!filterValue) return rows
            id = id[0]
            return rows.filter(row => filterValue?.[id] === row.values?.[id])
          }
        }),
        []
      )
      const datePerPage = rowsPerPage
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { selectedRowIds, pageIndex, pageSize, filters },
        selectedFlatRows,
        toggleRowSelected,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
        setPageSize,
        setAllFilters,
        toggleAllRowsSelected
      } = useTable(
        {
          columns,
          data,
          defaultColumn,
          filterTypes,
          sortTypes: {
            alphanumeric: (row1 = {}, row2 = {}, columnName = '') => {
              const rowOneColumn = row1?.values[columnName]
              const rowTwoColumn = row2?.values[columnName]

              return (rowOneColumn || '').toString().toLowerCase() >
                (rowTwoColumn || '').toString().toLowerCase()
                ? 1
                : -1
            }
          },
          initialState: { pageIndex: 0, pageSize: datePerPage }
        },
        useBlockLayout,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
          hooks.visibleColumns.push(columns => [
            {
              id: 'selection',
              width: 45,
              Header: ({ getToggleAllRowsSelectedProps }) => {
                return (
                  <div className="checkbox-wrapper">
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </div>
                )
              },
              Cell: ({ row }) => {
                return (
                  <div style={{ textAlign: 'center' }}>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      currentRow={row}
                      rows={rows}
                      toggleRowSelected={toggleRowSelected}
                    />
                  </div>
                )
              }
            },
            ...columns
          ])
        }
      )

      useImperativeHandle(ref, () => ({
        getRows: () => {
          return rows?.map(row => row.original)
        },
        previousPage,
        nextPage,
        canNextPage,
        canPreviousPage,
        rowCount: (rows || []).length,
        currentPage: pageIndex + 1,
        gotoPage,
        setPageSize,
        clearFilter: setAllFilters,
        toggleAllRowsSelected: toggleAllRowsSelected
      }))

      useEffect(() => {
        onRowSelectStateChange(selectedFlatRows)
      }, [selectedRowIds])

      useEffect(() => {
        setRowsPerPage(pageSize)
      }, [pageSize])

      useEffect(() => {
        setSelectedPage(pageIndex + 1)
      }, [pageIndex])

      useEffect(() => {
        setSelectedPageRowData((rows || []).length)
      }, [rows])

      useEffect(() => {
        setIsFilterApplied(filters)
      }, [filters])

      const isSort = column => {
        if (column?.isSorted) {
          return (
            <div
              style={{
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 5,
                marginTop: -4
              }}>
              <img
                style={{ display: 'block', marginTop: '3px' }}
                src={!column.isSortedDesc ? icons.caretUpOn : icons.caretUpOff}
                alt="Sort in ascending order"
              />
              <img
                style={{
                  display: 'block',
                  marginTop: '-3px'
                }}
                src={
                  column.isSortedDesc ? icons.caretDownOn : icons.caretDownOff
                }
                alt="Sort in descending order"
              />
            </div>
          )
        }
        if (column?.canSort) {
          return (
            <div
              style={{
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 5,
                marginTop: -4
              }}>
              <img
                style={{ display: 'block', marginTop: '3px' }}
                src={icons.caretUpOff}
                alt="Sort in ascending order"
              />
              <img
                style={{
                  display: 'block',
                  marginTop: '-3px'
                }}
                src={icons.caretDownOff}
                alt="Sort in descending order"
              />
            </div>
          )
        }
        return ''
      }

      const RenderRow = useCallback(
        ({ index, style }) => {
          const row = page[index]
          prepareRow(row)
          return (
            <div
              {...row.getRowProps({
                style
              })}
              className="tr">
              {row.cells.map(cell => {
                let styleBody = {}
                if (cell.column.minWidth !== 0) {
                  styleBody.minWidth = cell.column.minWidth
                }
                if (cell.column.flexGrow !== 0) {
                  styleBody.flexGrow = cell.column.flexGrow
                }
                return (
                  <div
                    {...cell.getCellProps({
                      style: {
                        textAlign: cell.column.textAlign,
                        width: cell.column.width,
                        ...styleBody
                      }
                    })}
                    className="td">
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        },
        [prepareRow, rows, selectedRowIds, page]
      )

      // Render the UI for your table
      return (
        <div {...getTableProps()} className="inspection-table">
          <div>
            {headerGroups.map(headerGroup => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr full-header">
                {headerGroup.headers.map(column => (
                  <div
                    {...column.getHeaderProps({
                      style: {
                        textAlign: column.textAlign,
                        width: column.width,
                        minWidth: column.minWidth,
                        flexGrow: column.flexGrow
                      }
                    })}
                    className="th">
                    <div>
                      {column.render('Header')}
                      {column.Header === 'Photos' && isPhotoLoading && (
                        <FontAwesomeIcon
                          color="#3DB3E2"
                          icon={faSpinner}
                          spin
                          className="m-l-sm"
                        />
                      )}
                      <span {...column.getSortByToggleProps()}>
                        {isSort(column)}
                      </span>
                    </div>
                    <div title="Filter">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div>
            {(rows || []).length ? (
              <FixedSizeList
                height={300}
                itemCount={(page || []).length}
                itemSize={35}
                className="full-body list-container">
                {RenderRow}
              </FixedSizeList>
            ) : (
              <></>
            )}
          </div>
        </div>
      )
    }
  )
)

const InspectionList = props => {
  const {
    user,
    context,
    inspect,
    setModalContent,
    openModal,
    closeModal,
    location,
    getInspectionDownloadMediaURL,
    editNewInspection,
    bulkUpdateInspectionsProfile,
    getFilteredInspectionList,
    downloadInspectionMedia,
    getDownloadState
  } = props || {}
  const { pathname } = location || {}
  const { inspectionList, inspectionMediaList, inspectionDownloadMediaURL } =
    inspect || {}
  const { data: allInspectionList, isRequesting, hasMadeInitialRequest } =
    inspectionList || {}
  const [showModal, setModal] = useState(false)
  const [isPhotoLoading, setIsPhotoLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [updateInspectionList, setUpdateInspectionList] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [filteredInspectionList, setFilteredInspectionList] = useState([])
  const [showCustomModal, dismissCustomModal] = useModals()
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageRowData, setSelectedPageRowData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const { hasPermission } = usePermissionGate('add-edit-inspection')
  const [currentRow, setCurrentRow] = useState(null)

  const [showConfirmModal, dismissConfirmModal] = useModals()

  useEffect(() => {
    setFilteredInspectionList(allInspectionList)
  }, [allInspectionList])

  // Date Filter Code
  const [outsideFilters, setOutsideFilters] = useState({})
  const [dates, setDates] = useState({ from: new Date(), to: new Date() })
  const [
    scheduleFiltersDropdownOpen,
    setScheduleFiltersDropdownOpen
  ] = useState(false)

  const outsideFiltersRef = useDetectClickOutside({
    onTriggered: () => setScheduleFiltersDropdownOpen(false)
  })

  let datePickerRef = null

  const modifiers = {
    start: dates.from,
    end: dates.to,
    past: { before: new Date(dates.from) },
    pastSelection: { before: dates.from },
    weekS: { daysOfWeek: [1] },
    weekE: { daysOfWeek: [0] },
    firstOfMonth: day => day.getDate() === 1,
    lastOfMonth: day => day.getDate() === numberOfDays(null, day.getMonth() + 1)
  }

  const getActiveScheduledFilterLabel = () => {
    const activeFilter = Object.keys(outsideFilters).find(
      filter => outsideFilters[filter]['active']
    )
    if (activeFilter !== undefined) {
      return outsideFilters[activeFilter]['label']
    }
  }

  const handleDayClick = day => {
    day.setHours(0, 0, 0)
    const range = DateUtils.addDayToRange(day, dates)

    setOutsideFilters(getOutsideFilters({ from: range.from, to: range.to }))
    setOutsideFilters(outsideFilters => {
      return {
        ...outsideFilters,
        custom: {
          ...outsideFilters['custom'],
          active: true
        }
      }
    })
    setDates(range)
  }

  const customPickerInput = React.forwardRef((props, ref) => {
    const value =
      dates.from && dates.to
        ? `${dateFnsFormat(dates.from, 'MM/dd')} - ${dateFnsFormat(
            dates.to,
            'MM/dd'
          )}`
        : props.value
    return (
      <input
        {...props}
        value={value}
        ref={ref}
        id="datepicker-input"
        readOnly
      />
    )
  })

  useEffect(() => {
    setOutsideFilters(
      getOutsideFilters({ from: new Date(), to: new Date() }, true)
    )
  }, [])

  useEffect(() => {
    if (scheduleFiltersDropdownOpen)
      datePickerRef && datePickerRef.hideDayPicker()
  }, [scheduleFiltersDropdownOpen])
  // Date filter code end

  const getFilterPath = pathname.substring(
    pathname.indexOf('/') + 1,
    pathname.lastIndexOf('/')
  )

  const tableRef = useRef()

  // useEffect(() => {
  //   getDefaultFilteredInspectionData()
  // }, [context, user])

  const getDefaultFilteredInspectionData = () => {
    const today = new Date()
    const priorDate = new Date().setDate(today.getDate() - 30)
    const convertTodayDate = formatDateString(today, 'DD-MMM-YY')
    const convertPriorDate = formatDateString(priorDate, 'DD-MMM-YY')
    getFilteredInspectionData(convertTodayDate, convertPriorDate)
  }

  const getFilteredInspectionData = (selectedTodayDate, selectedPriorDate) => {
    setSelectedPageRowData(0)
    setRowsPerPage(30)
    setUpdateInspectionList(false)
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    getFilteredInspectionList(
      pdbid,
      context,
      selectedTodayDate,
      selectedPriorDate,
      () => {
        setErrorMessage('')
      },
      () => {
        setErrorMessage('Something went wrong. Please try again later !!!')
      }
    )
    getInspectionDownloadMediaURL(pdbid, context)
    getDownloadState(pdbid, context)
  }

  useEffect(() => {
    if (updateInspectionList) {
      setOutsideFilters(
        getOutsideFilters({ from: new Date(), to: new Date() }, true)
      )
      getDefaultFilteredInspectionData()
    }
  }, [updateInspectionList])

  // useEffect(() => {
  //   const { user_metadata } = user || {}
  //   const { pdbid } = user_metadata || {}

  //   if (!isEmpty(context)) {
  //     console.log('first')
  //     getDownloadState(pdbid, context)
  //   }
  // }, [context])

  const onAllInspectionNoteHandler = (
    allNotes = [],
    unit = '',
    assigned_to = ''
  ) => {
    setModalContent(() => (
      <AllInspectionNoteModal
        {...props}
        allNotes={allNotes}
        unit={unit}
        inspector={assigned_to}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '649px',
      maxWidth: '100%',
      additionalClasses: 'inspection-note-modal'
    })
  }

  const toggleModal = row => {
    setModal(!showModal)
    setCurrentRow(row)
  }

  // Add Inspection Modal
  const onAddInspectionHandler = (row = {}, isEdit = false) => {
    setModalContent(() => (
      <AddInspectionModal
        {...props}
        editInspectionData={row}
        setUpdateInspectionList={setUpdateInspectionList}
        isEditInspection={isEdit}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '480px',
      maxWidth: '94%',
      additionalClasses: 'inspection-modal'
    })
  }

  // Inspection Assignment Modal
  const onInspectionAssignmentHandler = () => {
    let reConstructTableRow = []
    let selectedInspectionsData = []
    if ((selectedRows || []).length !== 0) {
      selectedInspectionsData = (selectedRows || []).map(row => row?.original)
      reConstructTableRow = []
      reConstructTableRow = (selectedRows || []).map(row => {
        const { original: u } = row || {}
        const { unit } = u || {}
        return unit
      })
    }
    setModalContent(() => (
      <InspectionAssignmentModal
        {...props}
        selectedInspectionUnits={reConstructTableRow || []}
        selectedInspectionsData={selectedInspectionsData || []}
        setUpdateInspectionList={setUpdateInspectionList}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '480px',
      maxWidth: '94%',
      additionalClasses: 'inspection-modal'
    })
  }

  // Re-open inspection
  const onUpdateInspectionStatus = (data = {}) => {
    // Meta Data
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    const { inspection_id, assigned_to, inspection_date } = data || {}
    let body = {
      assigned_to: assigned_to,
      inspection_date: formatDateString(inspection_date, 'DD-MMM-YY'),
      status: 'Reopened',
      approved_date: null,
      submitted_date: null,
      approved_by: null
    }
    editNewInspection({
      pdbid,
      context,
      body,
      inspectionId: inspection_id,
      onSuccess: () => {
        setUpdateInspectionList(true)
        setErrorMessage('')
      },
      onError: error => {
        setErrorMessage('Something went wrong. Please try again later !!!')
      }
    })
  }

  // Row option based on conditions
  const getRowOption = (row = {}) => {
    const { status } = row || {}
    let options = [
      {
        caption: 'Edit Inspection',
        icon: edit,
        action: () => onAddInspectionHandler(row, true)
      },
      {
        caption: 'Export Excel',
        icon: exportFile,
        action: () => exportSingleInspectionCSV([row], context, user, false)
      }
    ]
    if (status === 'Approved') {
      return [
        ...options,
        {
          caption: 'Reopen',
          icon: reopen,
          action: () => onUpdateInspectionStatus(row)
        }
      ]
    }
    return options || []
  }

  const exportCSV = () => {
    const { user_metadata } = user || {}
    const { properties, projects } = user_metadata || {}
    const { property: contextProperty, project: contextProject } = context || {}
    const property = properties.find(p => p.id === contextProperty)
    const project = projects.find(p => p.id === contextProject)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const rows = tableRef.current?.getRows() || []
    let defaultData = selectedRows.length
      ? selectedRows.map(row => row?.original)
      : rows
    const filterData = (defaultData || []).map(list => {
      const {
        building,
        unit,
        type,
        inspection_date,
        assigned_to_name,
        status
      } = list || {}
      return {
        Building: building || '',
        Unit: unit || '',
        Inspection: type ? `${type} Inspection` : '',
        'Inspection Date': inspection_date
          ? formatDateString(inspection_date, 'MM/DD/YYYY')
          : 'N/A',
        Inspector: assigned_to_name || '',
        Status: status || ''
      }
    })

    const data = filterData || []
    const keys = Object.keys(data[0] || [])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const csvContent = 'data:general/csv;charset=utf-8,' + csvData
    const encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names?.project}-${names?.property}-all-inspections-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const exportMultipleCSV = () => {
    let defaultData = selectedRows.length
      ? selectedRows.map(row => row?.original)
      : []
    exportSingleInspectionCSV(defaultData, context, user, true)
  }

  // Confirmation Modal for action buttons
  const onHandleApproveModal = ({
    msg,
    submitAction,
    dismissLabel,
    modalTitle,
    submitLabel,
    subMsg,
    additionalSubmitButtonClasses
  }) => {
    showCustomModal(
      { width: '480px' },
      <ConfirmationModal
        msg={msg}
        submit={submitAction}
        dismissLabel={dismissLabel}
        modalTitle={modalTitle}
        submitLabel={submitLabel}
        subMsg={subMsg}
        additionalSubmitButtonClasses={additionalSubmitButtonClasses}
        useLoading={true}
        dismiss={dismissCustomModal}
      />
    )
  }

  const tableColumns = headerData({
    getFilterPath,
    onAllInspectionNoteHandler,
    isPhotoLoading,
    setIsPhotoLoading,
    toggleModal,
    getRowOption,
    SelectColumnFilter,
    hasPermission
  })

  const columns = useMemo(() => tableColumns)

  const onHandleApproveInspections = () => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    let data = []
    if ((selectedRows || []).length !== 0) {
      ;(selectedRows || []).map(row => {
        const { original } = row || {}
        const {
          inspection_id,
          approved_by,
          approved_date,
          submitted_date,
          rawInspectionDate,
          assigned_to
        } = original || {}
        data.push({
          inspection_id,
          approved_by,
          assigned_to,
          approved_date,
          inspection_date: rawInspectionDate,
          status: 'Approved',
          submitted_date
        })
        return row
      })
      bulkUpdateInspectionsProfile({
        pdbid,
        context,
        body: {
          items: data || []
        },
        onSuccess: () => {
          setUpdateInspectionList(true)
          setErrorMessage('')
        },
        onError: () => {
          setErrorMessage('Something went wrong. Please try again later !!')
        }
      })
    }
  }

  const userSwiftActions = () => {
    if ((selectedRows || []).length !== 0) {
      const simplifyRows = (selectedRows || []).map(row => row?.original)
      if (
        (simplifyRows || []).every(element => element?.status === 'Submitted')
      ) {
        return (
          <button
            className="button is-primary-outlined"
            disabled={!hasPermission}
            onClick={e => {
              e.preventDefault()
              if (hasPermission) {
                onHandleApproveModal({
                  msg:
                    'You’re about to Approve the selected inspections. Once approved, this action cannot be undone and the forms cannot be reopened or edited.',
                  dismissLabel: 'Cancel',
                  modalTitle: 'Confirm',
                  submitLabel: 'Yes',
                  subMsg: 'Would you like to continue with approval?',
                  submitAction: () => {
                    onHandleApproveInspections()
                    dismissCustomModal()
                  }
                })
              }
            }}>
            Approve
          </button>
        )
      }
      if (
        (simplifyRows || []).every(
          element =>
            element?.status === 'Not Started' ||
            element?.status === 'Started' ||
            element?.status === 'Reopened'
        )
      ) {
        return (
          <button
            className="button is-primary"
            disabled={!hasPermission}
            onClick={e => {
              e.preventDefault()
              if (hasPermission) {
                onInspectionAssignmentHandler()
              }
            }}>
            Assign
          </button>
        )
      }
    }
  }

  const prevContext = usePrevious(context)

  const onHandleDaysDropdown = () => {
    if (outsideFilters && Object.keys(outsideFilters).length) {
      let filterKey = {}
      Object.keys(outsideFilters).forEach(filter => {
        if (outsideFilters[filter]['active']) {
          filterKey = outsideFilters[filter]
        }
      })
      if (Object.keys(filterKey || {}).length !== 0) {
        if (filterKey?.label === 'Tomorrow') {
          const today = new Date()
          const tomorrowDate = new Date().setDate(today.getDate() + 1)
          const convertTomorrowDate = formatDateString(
            tomorrowDate,
            'DD-MMM-YY'
          )
          getFilteredInspectionData(convertTomorrowDate, convertTomorrowDate)
        } else if (filterKey?.label === 'Last 30 Days') {
          const today = new Date()
          const priorDate = new Date().setDate(today.getDate() - 30)
          const convertTodayDate = formatDateString(today, 'DD-MMM-YY')
          const convertPriorDate = formatDateString(priorDate, 'DD-MMM-YY')
          getFilteredInspectionData(convertTodayDate, convertPriorDate)
        } else if (filterKey?.label === 'Yesterday') {
          const today = new Date()
          const yesterdayDate = new Date().setDate(today.getDate() - 1)
          const convertYesterdayDate = formatDateString(
            yesterdayDate,
            'DD-MMM-YY'
          )
          getFilteredInspectionData(convertYesterdayDate, convertYesterdayDate)
        } else if (filterKey?.label === 'All') {
          const today = new Date()
          const convertTodayDate = formatDateString(today, 'DD-MMM-YY')
          getFilteredInspectionData(0, convertTodayDate)
        } else if (filterKey?.label === 'Custom') {
          const { from, to } = dates || {}
          const convertFromDate = formatDateString(from, 'DD-MMM-YY')
          const convertToDate = formatDateString(to, 'DD-MMM-YY')
          getFilteredInspectionData(convertToDate, convertFromDate)
        }
      }
    }
  }

  useEffect(() => {
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged) {
      onHandleDaysDropdown()
    }
  }, [context])

  useEffect(() => {
    if (outsideFilters && Object.keys(outsideFilters).length) {
      onHandleDaysDropdown()
    }
  }, [outsideFilters])

  const onResetFilter = () => tableRef.current?.clearFilter([])
  const onResetSelectedRows = () =>
    tableRef.current?.toggleAllRowsSelected(false)

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  const hasMediaInEverySelectedRow = useMemo(() => {
    if (!selectedRows?.length) return false
    return selectedRows.every(row => row.original.hasMedia)
  }, [selectedRows])

  const downloadInspectionsPayload = useMemo(() => {
    const rows = cloneDeep(selectedRows)
    const originalRows = (rows || []).map(row => row.original)
    const groupedData = groupBy(originalRows, 'building')
    const payload = {
      items: []
    }

    // Remove array which do not have media
    for (let key in groupedData) {
      const job_lines = (groupedData[key] || [])
        .filter(media => media?.hasMedia)
        .map(group => ({
          unit_id: group.unit_id,
          inspection_id: group.inspection_id
        }))

      // Don't add data to payload if job lines are empty
      if ((job_lines || []).length) {
        payload.items.push({
          // Adding name attb from FE so all the download media at one time has same name
          name: formatDateString(new Date()),
          building_id: key,
          status: null,
          job_lines
        })
      }
    }

    return payload
  }, [selectedRows])

  const handleConfirmDownloadMedia = useCallback(() => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}

    if (downloadInspectionsPayload?.items?.length) {
      return showConfirmModal(
        { width: '480px' },
        <ConfirmationModal
          modalTitle="Do you want to proceed?"
          dismissLabel="Cancel"
          submitLabel="Confirm"
          msg="Once you confirm, the download will be added to the queue. You will be able to download the zip folder onto your device when the download is ready from the Downloads section. However, please note that that the download link will expire after 72 hours from the time the link is ready."
          submit={() => {
            downloadInspectionMedia(
              pdbid,
              context,
              downloadInspectionsPayload,
              () => {},
              () => {
                setErrorMessage(
                  'Something went wrong. Please try again later !!!'
                )
              }
            )
            dismissConfirmModal()
          }}
          dismiss={dismissConfirmModal}
          useLoading
        />
      )
    }
    return showConfirmModal(
      { width: '480px' },
      <ConfirmationModal
        modalTitle="Warning"
        dismissLabel="OK"
        msg="Unfortunately, this inspection does not have any media files to download. Please change your selection."
        showSubmitButton={false}
        dismiss={dismissConfirmModal}
        useLoading
      />
    )
  }, [downloadInspectionsPayload, user, context])

  return (
    <div id="inspection-list-container" className="inspection-list-container ">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5">All Inspections</h2>
          </div>
          <div className="action-button">
            <div className="inspection-action-buttons">
              <button
                className="button is-success"
                disabled={!hasPermission}
                onClick={e => {
                  e.preventDefault()
                  if (hasPermission) {
                    onAddInspectionHandler()
                  }
                }}>
                New Inspection
              </button>
              {userSwiftActions()}
              <div
                style={{ display: 'inline-block' }}
                className="calendar-container">
                <div
                  className={`dropdown filter-dropdown ${
                    scheduleFiltersDropdownOpen ? 'is-active' : ''
                  }`}
                  id="filter-dropdown"
                  ref={outsideFiltersRef}>
                  <div className="dropdown-trigger">
                    <button
                      className="button m-r-md is-secondary"
                      type="button"
                      aria-haspopup={true}
                      aria-controls="filter-options"
                      onClick={() =>
                        setScheduleFiltersDropdownOpen(
                          !scheduleFiltersDropdownOpen
                        )
                      }>
                      <img
                        src={calendar}
                        alt="Filter data"
                        className="m-r-xs"
                      />
                      :{' '}
                      {Object.keys(outsideFilters).length &&
                        getActiveScheduledFilterLabel()}
                    </button>
                  </div>
                  <div
                    className="dropdown-menu"
                    role="menu"
                    id="filter-options">
                    <div className="dropdown-content has-text-left">
                      {Object.keys(outsideFilters).map((filter, index) => (
                        <button
                          className={cn(
                            'button filter-button',
                            { 'is-primary': outsideFilters?.[filter]?.active },
                            {
                              'is-secondary': !outsideFilters?.[filter]?.active
                            }
                          )}
                          onClick={() => {
                            const currentActiveValue =
                              outsideFilters[filter]['active']
                            const activeFilter = Object.keys(
                              outsideFilters
                            ).find(filter => outsideFilters[filter]['active'])
                            setOutsideFilters(
                              getOutsideFilters({
                                from: dates.from,
                                to: dates.to
                              })
                            )
                            setOutsideFilters(outsideFilters => {
                              return {
                                ...outsideFilters,
                                [filter]: {
                                  ...outsideFilters[filter],
                                  active:
                                    filter === activeFilter
                                      ? true
                                      : !currentActiveValue
                                }
                              }
                            })
                            setScheduleFiltersDropdownOpen(false)
                          }}
                          key={`custom-filter-${index}`}>
                          {outsideFilters[filter]['icon'] && (
                            <img
                              src={outsideFilters[filter]['icon']}
                              alt={outsideFilters[filter]['label']}
                              className="p-r-md"
                              style={{ width: 34 }}
                            />
                          )}
                          {outsideFilters[filter]['label']}
                        </button>
                      ))}
                      <div
                        style={{
                          borderRadius: 4,
                          paddingLeft: '1em',
                          paddingRight: '1em',
                          paddingBottom: 'calc(0.5em - 1px)',
                          background:
                            Object.keys(outsideFilters).length &&
                            outsideFilters['custom']['active']
                              ? '#3cb3e2'
                              : 'inherit'
                        }}>
                        <DayPickerInput
                          onDayChange={handleDayClick}
                          dayPickerProps={{
                            modifiers: modifiers,
                            firstDayOfWeek: calendarConfig.firstDayOfWeek,
                            weekdaysShort: calendarConfig.weekdaysShort,
                            selectedDays: [
                              dates.from,
                              { from: dates.from, to: dates.to }
                            ],
                            month: new Date(dates.from)
                          }}
                          hideOnDayClick={false}
                          formatDate={formatDate}
                          format={'MM/dd'}
                          parseDate={parseDate}
                          placeholder={`${dateFnsFormat(
                            new Date(),
                            'MM/dd'
                          )} - ${dateFnsFormat(new Date(), 'MM/dd')}`}
                          component={customPickerInput}
                          ref={ref => (datePickerRef = ref)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(selectedRows || []).length !== 0 && (
                <>
                  <button
                    className="button is-primary-outlined"
                    onClick={e => {
                      e.preventDefault()
                      exportMultipleCSV()
                    }}>
                    Export Inspection(s)
                  </button>
                  <button
                    //data-tip
                    //data-for="downloadMedia"
                    className="button is-primary-outlined"
                    // className={`button is-primary-outlined ${
                    //   !hasMediaInEverySelectedRow ? 'isDisabled' : ''
                    // }`}
                    onClick={e => {
                      e.preventDefault()
                      handleConfirmDownloadMedia()
                    }}>
                    Download Media
                  </button>
                </>
              )}
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={() => {
                    onResetFilter()
                  }}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <button className="export" onClick={() => exportCSV()}>
              <img src={exportFile} alt="Export Inspections" />
              Export
            </button>
          </div>
          {errorMessage !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
        {hasMadeInitialRequest && !isRequesting ? (
          <Styles>
            <Table
              ref={tableRef}
              columns={columns}
              data={filteredInspectionList}
              onRowSelectStateChange={setSelectedRows}
              isPhotoLoading={isPhotoLoading}
              setSelectedPage={setSelectedPage}
              setSelectedPageRowData={setSelectedPageRowData}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Styles>
        ) : (
          <BigLoading />
        )}
        <div className="table-pagination">
          {hasMadeInitialRequest && !isRequesting ? (
            <Paginator
              previousPage={tableRef.current?.previousPage}
              nextPage={tableRef.current?.nextPage}
              rowsPerPage={rowsPerPage}
              rowCount={selectedPageRowData}
              currentPage={selectedPage}
              onChangePage={page => tableRef.current?.gotoPage(page - 1)}
              setRowsPerPage={pageSize => {
                const { setPageSize } = tableRef.current
                setPageSize(pageSize)
              }}
              limit={[30, 50, 70, 100]}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      {showModal && (
        <InspectionMediaModal
          {...props}
          onToggle={toggleModal}
          onError={setErrorMessage}
          currentRow={currentRow}
          inspectionMediaList={inspectionMediaList}
          inspectionDownloadMediaURL={inspectionDownloadMediaURL}
        />
      )}
    </div>
  )
}

export default InspectionList
