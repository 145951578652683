import React from 'react'
import InspectionCell from './components/InspectionCell'
import { Link } from 'react-router-dom'
import { note } from 'ui/icons'
import InspectionMedia from '../../components/inspection-media'
import DataTableMenu from '../../components/data-table-menu'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'
import { capitalize } from '../../utils/helperFunctions'

export const headerData = ({
  getFilterPath,
  onAllInspectionNoteHandler,
  isPhotoLoading,
  setIsPhotoLoading,
  toggleModal,
  getRowOption,
  SelectColumnFilter,
  hasPermission
}) => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Building',
        accessor: 'building',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 90,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        textAlign: 'center',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 80,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Inspection',
        accessor: 'type',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        flexGrow: 1,
        //minWidth: 180,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { inspection_id, type, unit, inspection_date, assigned_to } =
            original || {}
          return (
            <div className="cell-item">
              <Link
                className="link"
                to={{
                  pathname: `/${getFilterPath}/manage-inspection/${inspection_id}`,
                  state: {
                    id: inspection_id || '',
                    type: type || '',
                    date: inspection_date || '',
                    unit: unit || '',
                    inspector: assigned_to || ''
                  }
                }}>
                {`${capitalize(type || '')} Inspection`}
              </Link>
            </div>
          )
        }
      },
      {
        Header: 'Inspected Date',
        accessor: 'inspection_date',
        textAlign: 'left',
        width: 140,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { inspection_date } = original || {}
          return (
            <div className="cell-item" style={{ paddingLeft: '15px' }}>
              {inspection_date}
            </div>
          )
        }
      },
      {
        Header: 'Inspector/Team',
        accessor: 'inspectiorOrTeam',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        //minWidth: 200,
        //maxWidth: 500,
        flexGrow: 1,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 160,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Notes',
        accessor: 'has_notes',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 70,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { has_notes, allNotes, unit, assigned_to_name } = original || {}
          if (has_notes) {
            return (
              <div className="cell-item note-icon">
                <img
                  title="View Notes"
                  className="is-pointer is-icon"
                  onClick={() =>
                    onAllInspectionNoteHandler(allNotes, unit, assigned_to_name)
                  }
                  src={note}
                  alt="note"
                />
              </div>
            )
          }
          return <></>
        }
      },
      {
        Header: 'Photos',
        accessor: 'inspection_id',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 70,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          return (
            <div className="cell-item">
              {original.hasMedia && (
                <InspectionMedia
                  setIsPhotoLoading={setIsPhotoLoading}
                  isPhotoLoading={isPhotoLoading}
                  customClass="link"
                  onToggle={() => toggleModal(original)}
                  row={original}
                />
              )}
            </div>
          )
        }
      },
      {
        Header: '',
        accessor: 'inspection_id1',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 40,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original, index } = currentRow || {}
          const currentOptions = getRowOption(original, index)
          return (
            <div className="menu-item">
              {hasPermission && (
                <DataTableMenu options={currentOptions || []} row={original} />
              )}
            </div>
          )
        }
      }
    ]
  }
]
