import React, { useEffect, useState, useCallback, useMemo } from 'react'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { close, closeFilled } from 'ui/icons'
import ErrorTemplate from '../../components/forms-helper/ErrorTemplate'
import PermissionRuleFieldTemplate from '../../components/forms-helper/PermissionRuleFieldTemplate'
import PasswordField from '../../components/forms-helper/PasswordField'
import FieldTemplate from '../../components/forms-helper/FieldTemplate'
import ToggleEnableLogin from './ToggleEnableLogin'
import { ALLUSERTYPE } from './metadata'
import Short from 'short-uuid'
import { selectStyles } from '../../components/forms-helper/MultiDropdownStyles'
import {
  destructDataForUserCreationPayload,
  filteredProjectList,
  filteredPropertiesList,
  getSelectedProperty
} from './helper-methods'
import {
  emailPolicy,
  passwordPolicy
} from '../../components/forms-helper/ValidationPolicies'
import GeneralField from '../../components/forms-helper/GeneralField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './index.scss'
import ReactTooltip from 'react-tooltip'
import '../../styles/common.scss'

const AddNewUser = props => {
  const {
    history,
    user,
    createOrEditUser,
    setAlert,
    setupUsers,
    checkEmailExists,
    resetCheckEmail,
    allPropertiesList,
    allRolesList
  } = props || {}
  const { pdbid } = user?.user_metadata

  // Redux
  const { propertiesList: ALLPROPERTIES, rolesList: ALLROLES, verifiedEmail } =
    setupUsers || {}

  const [permissionErrorMessage, setPermissionErrorMessage] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [allPropertyList, setAllPropertyList] = useState(ALLPROPERTIES)
  const [UserName, setUserName] = useState('')
  const [userTitle, setUserTitle] = useState('')
  const [userType, setUserType] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [userAltPhone, setUserAltPhone] = useState('')
  const [userNote, setUserNote] = useState('')

  const [shouldGeneratePassword, setShouldGeneratePassword] = useState(true)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isValidpassword, setIsValidPassword] = useState(false)
  const [isValidConfirmpassword, setIsValidConfirmPassword] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [email, setEmail] = useState('')

  const {
    loading: checkEmailLoading,
    error: checkEmailError,
    emailVerified: checkEmailVerified,
    email: checkedEmail,
    serverError: checkEmailServerError
  } = verifiedEmail || {}

  const filteredRoles = useMemo(() => {
    const { roles } = user || {}
    const isPropertyManager = (roles || []).some(
      role => role?.name === 'Property Manager'
    )

    let filtered = ALLROLES || []

    if (userType?.name === 'Vendor') {
      filtered = (filtered || []).filter(role => role.role_type === 'Vendor')
    } else {
      filtered = (filtered || []).filter(role => role.role_type !== 'Vendor')
    }

    return isPropertyManager
      ? (filtered || []).filter(role => role?.name !== 'Administrator')
      : filtered
  }, [user, ALLROLES, userType])

  const getValidEmail = value => value?.match(emailPolicy) !== null
  const getValidPassword = value => value?.match(passwordPolicy) !== null

  const optimizedEmailHandleValidation = useCallback(value => {
    if (!getValidEmail(value)) {
      setEmailError(
        'The email address you entered is invalid. Please enter your email address using standard format.'
      )
    } else {
      setEmailError('')
    }
  }, [])

  const optimizedPasswordValidation = useCallback((value, type) => {
    if (!getValidPassword(value)) {
      type(true)
    } else {
      type(false)
    }
  }, [])

  const getStaffRole = useMemo(
    () => (ALLROLES || []).filter(d => d.name === 'Staff'),
    [ALLROLES]
  )

  useEffect(() => {
    setAllPropertyList(ALLPROPERTIES)
  }, [ALLPROPERTIES])

  // For vendor role change in type dropdown
  // const getVendorRole = useMemo(
  //   () => (ALLROLES || []).filter(d => d.name === 'Vendor'),
  //   [ALLROLES]
  // )
  // useEffect(() => {
  //   if (userType?.name === 'Vendor') {
  //     let updatedRole = [...selectedPermissions]
  //     updatedRole = (updatedRole || []).map(d => ({
  //       ...d,
  //       role: getVendorRole || [],
  //       login_access: true
  //     }))
  //     setSelectedPermissions(updatedRole)
  //   }
  //   if (userType?.name !== 'Vendor') {
  //     let updatedRole = [...selectedPermissions]
  //     updatedRole = (updatedRole || []).map(d => {
  //       const { role } = d || {}
  //       if (role[0].name === 'Vendor') {
  //         d.role = getStaffRole || []
  //       }
  //       return d
  //     })
  //     setSelectedPermissions(updatedRole)
  //   }
  // }, [userType])

  // For vendor role default role and login
  // role: userType?.name === 'Vendor' ? getVendorRole : getStaffRole,
  // login_access: userType?.name === 'Vendor' ? true : false,

  // useEffect(() => {
  //   allPropertiesList(pdbid)
  //   allRolesList(pdbid)
  // }, [])

  useEffect(() => {
    if (!(selectedPermissions || []).length && (getStaffRole || []).length) {
      setSelectedPermissions([
        {
          internal_permission_rule_id: Short.uuid(),
          properties: [],
          projects: [],
          role: getStaffRole,
          login_access: true,
          action: null,
          status: null
        }
      ])
    }
    return () => resetCheckEmail()
  }, [getStaffRole])

  const resetErrorMessage = () => {
    setPermissionErrorMessage('')
  }

  const onHandleNewPermission = () => {
    resetErrorMessage()
    const tempData = {
      internal_permission_rule_id: Short.uuid(),
      properties: [],
      projects: [],
      role: getStaffRole,
      login_access: true,
      action: null,
      status: null
    }

    setSelectedPermissions(prev => [...prev, tempData])
  }

  const onHandleRemovePermission = id => {
    resetErrorMessage()
    let newPermission = [...selectedPermissions]
    newPermission = (newPermission || []).filter(
      perm => perm?.internal_permission_rule_id !== id
    )
    const getAllProperties = [...newPermission]
      .flatMap(curr_permission => curr_permission?.properties)
      ?.map(curr_property => curr_property?.property_id)
    if ((getAllProperties || []).length) {
      const filterProperties = (ALLPROPERTIES || []).filter(
        curr_property =>
          !(getAllProperties || [])?.includes(curr_property?.property_id)
      )
      setAllPropertyList(filterProperties)
    } else {
      setAllPropertyList(ALLPROPERTIES)
    }
    setSelectedPermissions(newPermission)
  }

  const onHandleSelectedProperties = (id, data, index) => {
    resetErrorMessage()
    const isSelectAllSelected = data.filter(
      value => value.property_id == 'select-all'
    )
    if (isSelectAllSelected.length != 0) {
      const newProperties = getSelectedProperty(selectedPermissions, id)
      allPropertyList.map(v => {
        if ((newProperties || []).length) {
          newProperties[0].properties.push(v)
          newProperties[0].projects =
            filteredProjectList([v], ALLPROPERTIES) || []
        }
      })
      onUpdateSelectedPermissionOnIndex(
        selectedPermissions,
        index,
        newProperties,
        setSelectedPermissions
      )
    } else {
      const newProperties = getSelectedProperty(selectedPermissions, id)
      if ((newProperties || []).length) {
        newProperties[0].properties = data[0] === null ? [] : data
        newProperties[0].projects =
          filteredProjectList(data, ALLPROPERTIES) || []
        onUpdateSelectedPermissionOnIndex(
          selectedPermissions,
          index,
          newProperties,
          setSelectedPermissions
        )
      }
    }
  }

  const onUpdateSelectedPermissionOnIndex = (
    selectedPermissions,
    index,
    newProperties,
    setSelectedPermissions
  ) => {
    const allSelectedPermission = [...(selectedPermissions || [])]
    allSelectedPermission[index] = newProperties[0]
    setSelectedPermissions(allSelectedPermission)
  }

  useEffect(() => {
    const getAllProperties = [...selectedPermissions]
      .flatMap(curr_permission => curr_permission?.properties)
      ?.map(curr_property => curr_property?.property_id)
    if ((getAllProperties || []).length) {
      const filterProperties = (ALLPROPERTIES || []).filter(
        curr_property =>
          !(getAllProperties || [])?.includes(curr_property?.property_id)
      )
      setAllPropertyList(filterProperties)
    } else {
      setAllPropertyList(ALLPROPERTIES)
    }
  }, [selectedPermissions])

  const onHandleUserRole = (id, data, index) => {
    resetErrorMessage()
    const newProperties = getSelectedProperty(selectedPermissions, id)
    if ((newProperties || []).length && (data || []).length) {
      newProperties[0].role = data[0] === null ? [] : data
      if (data[0]?.name === 'Vendor') {
        newProperties[0].login_access = true
      }
      onUpdateSelectedPermissionOnIndex(
        selectedPermissions,
        index,
        newProperties,
        setSelectedPermissions
      )
    }
  }

  const onHandleEnableLogin = (id, data, index) => {
    resetErrorMessage()
    const newProperties = getSelectedProperty(selectedPermissions, id)
    if ((newProperties || []).length) {
      newProperties[0].login_access = data || false
      onUpdateSelectedPermissionOnIndex(
        selectedPermissions,
        index,
        newProperties,
        setSelectedPermissions
      )
    }
  }

  const onHandleUserProject = (id, data, index) => {
    resetErrorMessage()
    const newProperties = getSelectedProperty(selectedPermissions, id)
    if ((newProperties || []).length) {
      newProperties[0].projects = data || []
      newProperties[0].properties =
        filteredPropertiesList(data, ALLPROPERTIES) || []
      onUpdateSelectedPermissionOnIndex(
        selectedPermissions,
        index,
        newProperties,
        setSelectedPermissions
      )
    }
  }

  const notValidPasswordError = resetState => {
    return (
      <ErrorTemplate>
        <div>
          <p>Invalid password. Password must match the following criteria:</p>
          <ol className="password-error">
            <li>Password must contain at least 8 Characters.</li>
            <li>An Uppercase character.</li>
            <li>A lowercase character.</li>
            <li>A Number.</li>
            <li>A Special Character.</li>
          </ol>
        </div>
      </ErrorTemplate>
    )
  }

  const toggleGeneratePassword = value => {
    setShouldGeneratePassword(value)
    setPassword('')
    setConfirmPassword('')
    setIsValidConfirmPassword(false)
    setIsValidPassword(false)
  }

  const checkEnableLoginAccessInPermissionRule = useMemo(
    () =>
      (selectedPermissions || []).filter(perm => perm?.login_access)?.length,
    [selectedPermissions]
  )

  useEffect(() => {
    if (!checkEnableLoginAccessInPermissionRule) {
      toggleGeneratePassword(true)
    }
  }, [checkEnableLoginAccessInPermissionRule])

  const isSubmitButtonEnable = useMemo(() => {
    if (
      !(UserName || '').trim() ||
      !(email || '').trim() ||
      emailError ||
      !userType
    ) {
      return true
    }
    if (
      !checkEmailVerified ||
      checkEmailError ||
      checkEmailLoading ||
      checkEmailServerError
    ) {
      return true
    }
    if (
      checkEnableLoginAccessInPermissionRule &&
      !shouldGeneratePassword &&
      (!(password || '').trim() ||
        !(confirmPassword || '').trim() ||
        isValidpassword ||
        isValidConfirmpassword ||
        (password || '').trim() !== (confirmPassword || '').trim())
    ) {
      return true
    }
    if (!(selectedPermissions || []).length) {
      return true
    }
    if ((selectedPermissions || []).length) {
      const checkIfPermssionSetEmpty = (selectedPermissions || []).map(
        perm_set => {
          const { properties, projects, role } = perm_set || {}
          if (
            !(properties || []).length ||
            !(projects || []).length ||
            !(role || []).length
          ) {
            return true
          }

          return false
        }
      )
      const isEmpty = (checkIfPermssionSetEmpty || []).filter(d => d === true)
      if ((isEmpty || []).length) {
        return true
      }
    }
    return false
  }, [
    UserName,
    email,
    shouldGeneratePassword,
    password,
    confirmPassword,
    isValidConfirmpassword,
    isValidpassword,
    emailError,
    selectedPermissions,
    userType,
    checkEnableLoginAccessInPermissionRule,
    checkEmailError,
    checkEmailVerified,
    checkEmailLoading,
    verifiedEmail,
    checkEmailServerError
  ])

  const onSuccess = () => {
    setSubmitting(false)
    history.push('/setup/users')
    setAlert('User is successfully created', 'success')
  }

  const onError = message => {
    setSubmitting(false)
    if (message) {
      setAlert(message, 'danger')
    } else {
      setAlert('Something went wrong. Please try again later!!!', 'danger')
    }
  }

  const onHandleAddUser = () => {
    setSubmitting(true)
    const permission_rules = destructDataForUserCreationPayload(
      selectedPermissions,
      ALLPROPERTIES
    )

    const body = {
      user_id: null,
      auth_id: null,
      email,
      name: UserName,
      type: userType?.name,
      title: userTitle || null,
      phone: userPhone || null,
      alt_phone: userAltPhone || null,
      notes: userNote || null,
      hire_date: null,
      term_date: null,
      is_inactive: 'false',
      app_access: null,
      permission_rules
    }

    if (!shouldGeneratePassword) {
      body.password = password
    }

    createOrEditUser(pdbid, body, onSuccess, onError)
  }

  const onEmailCheckSuccess = () => {}
  const onEmailCheckError = () => {}

  const onHandleCheckExistingEmail = e => {
    const email = e?.target?.value?.trim()?.toLowerCase()
    checkEmailExists(pdbid, email, onEmailCheckSuccess, onEmailCheckError)
  }

  return (
    <div className="columns is-multiline invite-user">
      <div className="column is-half animated fadeIn">
        <div className="box is-shadowless has-transition-opacity">
          <FieldTemplate>
            <div className="label-heading column">
              <strong className="is-size-5">Personal Information</strong>
            </div>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Name">
              <input
                id="name"
                name="name"
                value={UserName}
                onChange={e => setUserName(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Name"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Email Address">
              <div
                className={`control ${checkEmailLoading ? 'is-loading' : ''}`}>
                <input
                  id="email"
                  name="email"
                  value={email}
                  type="text"
                  autoComplete="off"
                  onChange={e => {
                    const value = e?.target?.value?.trim()?.toLowerCase()
                    setEmail(value)
                    optimizedEmailHandleValidation(value)
                    if (checkEmailError || checkedEmail !== value) {
                      resetCheckEmail()
                    }
                  }}
                  onBlur={e => {
                    if (emailError === '') {
                      onHandleCheckExistingEmail(e)
                    }
                  }}
                  placeholder="Enter Email Address"
                  className="input form-control"></input>
              </div>
            </GeneralField>
          </FieldTemplate>
          {emailError !== '' && (
            <ErrorTemplate>
              <div>
                <p>{emailError}</p>
                <p>
                  Example:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    noname@myturnable.com
                  </span>
                </p>
              </div>
            </ErrorTemplate>
          )}
          {checkEmailError && (
            <ErrorTemplate>
              <div>
                <p>
                  We’re sorry. This email address is already associated with an
                  account. Please try a different email address.
                </p>
              </div>
            </ErrorTemplate>
          )}
          {checkEmailServerError && (
            <ErrorTemplate>
              <div>
                <p>Something went wrong. Please try again later !!!</p>
              </div>
            </ErrorTemplate>
          )}
          <FieldTemplate>
            <GeneralField fieldLabel="Title" isRequired={false}>
              <input
                id="title"
                name="title"
                value={userTitle}
                onChange={e => setUserTitle(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Title"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Type">
              <div style={{ marginTop: '2px' }}>
                <MultiSelectDropdown
                  displayKey="name"
                  value={userType}
                  defaultValues={ALLUSERTYPE}
                  onChange={v => setUserType(v)}
                  dropdownIcon={true}
                  isMulti={false}
                  placeholder="Select User Type"
                  noOptionsMessage="No Type Available"
                  customStyles={selectStyles}
                  menuPortalTarget={document.body}
                />
              </div>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Phone" isRequired={false}>
              <input
                id="phone"
                name="phone"
                value={userPhone}
                onChange={e => setUserPhone(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Phone Number"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Alt Phone" isRequired={false}>
              <input
                id="altphone"
                name="altphone"
                value={userAltPhone}
                onChange={e => setUserAltPhone(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Alt Phone Number"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate isDisabledVcentered>
            <GeneralField fieldLabel="Notes" isRequired={false}>
              <textarea
                className="textarea  input form-control"
                rows={3}
                style={{ resize: 'none', marginTop: '2px' }}
                defaultValue={userNote}
                onChange={e => setUserNote(e.target.value)}
              />
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <div className="label-heading column">
              <strong className="is-size-5">Permission Rules</strong>
            </div>
          </FieldTemplate>
          {(selectedPermissions || []).map((userPerm, i) => {
            return (
              <div className="columns is-vcentered mb-0" key={i}>
                <div
                  className="column is-full is-10-fullhd is-offset-1-fullhd"
                  key={i}>
                  <div className="user-permission-container">
                    <div className="columns is-desktop is-mobile mb-0">
                      <div className="column permission-label">
                        <strong>{`Permission Rule ${i + 1}:`}</strong>
                        {(selectedPermissions || []).length > 1 && (
                          <img
                            onClick={() =>
                              onHandleRemovePermission(
                                userPerm?.internal_permission_rule_id
                              )
                            }
                            alt="Delete Permission Rule"
                            className="permission-delete"
                            src={close}
                          />
                        )}
                      </div>
                    </div>
                    <PermissionRuleFieldTemplate>
                      <GeneralField fieldLabel="Properties">
                        <MultiSelectDropdown
                          displayKey="property_name"
                          uniqueKey="property_id"
                          key={userPerm?.internal_permission_rule_id}
                          value={userPerm?.properties}
                          defaultValues={allPropertyList}
                          dropdownIcon={true}
                          onChange={v =>
                            onHandleSelectedProperties(
                              userPerm?.internal_permission_rule_id,
                              v,
                              i
                            )
                          }
                          isMulti={true}
                          placeholder="Select Properties"
                          noOptionsMessage={
                            ALLPROPERTIES.length == 0
                              ? 'No Properties Available'
                              : 'All properties have already been selected'
                          }
                          customStyles={selectStyles}
                        />
                      </GeneralField>
                    </PermissionRuleFieldTemplate>
                    <PermissionRuleFieldTemplate>
                      <GeneralField fieldLabel="Projects">
                        <MultiSelectDropdown
                          displayKey="project_name"
                          uniqueKey="project_id"
                          key={userPerm?.internal_permission_rule_id}
                          value={userPerm?.projects}
                          dropdownIcon={true}
                          defaultValues={filteredProjectList(
                            userPerm?.properties,
                            ALLPROPERTIES
                          )}
                          onChange={v =>
                            onHandleUserProject(
                              userPerm?.internal_permission_rule_id,
                              v,
                              i
                            )
                          }
                          isMulti={true}
                          placeholder="Select Projects"
                          noOptionsMessage="No Projects Available"
                          customStyles={selectStyles}
                        />
                      </GeneralField>
                    </PermissionRuleFieldTemplate>
                    <PermissionRuleFieldTemplate>
                      <GeneralField
                        fieldLabel="Enable Login"
                        isRequired={false}>
                        <ToggleEnableLogin
                          onChange={v =>
                            onHandleEnableLogin(
                              userPerm?.internal_permission_rule_id,
                              v,
                              i
                            )
                          }
                          isActive={userPerm?.login_access}
                        />
                      </GeneralField>
                    </PermissionRuleFieldTemplate>
                    <PermissionRuleFieldTemplate>
                      <GeneralField fieldLabel="Role">
                        <MultiSelectDropdown
                          displayKey="name"
                          key={userPerm?.internal_permission_rule_id}
                          value={userPerm?.role}
                          defaultValues={filteredRoles}
                          dropdownIcon={true}
                          onChange={v =>
                            onHandleUserRole(
                              userPerm?.internal_permission_rule_id,
                              [v],
                              i
                            )
                          }
                          isMulti={false}
                          isDisabled={!userPerm?.login_access}
                          placeholder="Select Role"
                          noOptionsMessage="No Roles Available"
                          customStyles={selectStyles}
                        />
                      </GeneralField>
                    </PermissionRuleFieldTemplate>
                  </div>
                </div>
              </div>
            )
          })}
          {permissionErrorMessage !== '' && (
            <ErrorTemplate>
              <p>{permissionErrorMessage}</p>
            </ErrorTemplate>
          )}
          <FieldTemplate>
            <div className="column">
              <label className="link" onClick={() => onHandleNewPermission()}>
                + Add Another Permission Rule
              </label>
            </div>
          </FieldTemplate>
          {checkEnableLoginAccessInPermissionRule ? (
            <>
              {' '}
              <FieldTemplate>
                <div className="label-heading column">
                  <strong>Password</strong>
                </div>
              </FieldTemplate>
              <FieldTemplate>
                <div className="column">
                  <label>
                    The password will be shared with the user via email
                  </label>
                </div>
              </FieldTemplate>
              <FieldTemplate>
                <div className="column">
                  <label
                    className={`checkbox ${shouldGeneratePassword &&
                      'is-active'}`}>
                    <span
                      className={`checkbox-input ${shouldGeneratePassword &&
                        'is-active'}`}>
                      <span className="checkbox-inner" />
                      <input
                        type="checkbox"
                        className="checkbox-original"
                        checked={shouldGeneratePassword}
                        name="generatepassword"
                        onChange={e => toggleGeneratePassword(e.target.checked)}
                      />
                    </span>
                    <span
                      className={`checkbox-label ${shouldGeneratePassword &&
                        'is-active'}`}>
                      Generate Password
                    </span>
                  </label>
                </div>
              </FieldTemplate>
              {!shouldGeneratePassword && (
                <>
                  <FieldTemplate>
                    <GeneralField fieldLabel="Password">
                      <PasswordField
                        id="password"
                        name="password"
                        value={password}
                        onChange={e => {
                          let value = e.target.value
                          if (!value.includes(' ')) {
                            setConfirmPassword('')
                          }
                          value = value.replace(' ', '')
                          setPassword(value)
                          setIsValidConfirmPassword(false)
                          optimizedPasswordValidation(value, setIsValidPassword)
                        }}
                      />
                    </GeneralField>
                  </FieldTemplate>
                  {isValidpassword && notValidPasswordError(setIsValidPassword)}
                  <FieldTemplate>
                    <GeneralField fieldLabel="Confirm Password">
                      <PasswordField
                        id="confirmpassword"
                        name="confirmpassword"
                        value={confirmPassword}
                        disabled={!(password || '').trim() || isValidpassword}
                        onChange={e => {
                          let value = e.target.value
                          value = value.replace(' ', '')
                          setConfirmPassword(value)
                          optimizedPasswordValidation(
                            value,
                            setIsValidConfirmPassword
                          )
                        }}
                      />
                    </GeneralField>
                  </FieldTemplate>
                  {isValidConfirmpassword &&
                    notValidPasswordError(setIsValidConfirmPassword)}
                  {!isValidpassword &&
                    !isValidConfirmpassword &&
                    password &&
                    confirmPassword &&
                    (password || '').trim() !==
                      (confirmPassword || '').trim() && (
                      <ErrorTemplate>
                        <p>Password and Confirm Password do not match</p>
                      </ErrorTemplate>
                    )}
                </>
              )}
            </>
          ) : null}

          <div>
            <div className="button-wrapper">
              <button
                className="button main-button is-secondary m-r-md"
                onClick={() => history.push('/setup/users')}>
                Cancel
              </button>
              <span data-tip data-for="addNewUser">
                <button
                  onClick={e => {
                    e.preventDefault()
                    if (!isSubmitButtonEnable) {
                      onHandleAddUser()
                    }
                  }}
                  className={`button main-button is-primary ${
                    isSubmitButtonEnable ? 'isDisabled' : ''
                  }`}>
                  Add User
                  {isSubmitting && (
                    <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                  )}
                </button>
              </span>
              <ReactTooltip
                className="customTooltipTheme"
                id="addNewUser"
                place="top"
                disable={!isSubmitButtonEnable}
                effect="solid">
                <div>
                  <p>
                    Please fill all the required (
                    <span className="required-star">*</span>) fields
                  </p>
                </div>
              </ReactTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddNewUser
